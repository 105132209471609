<template>
  <div class="ss-backpage">
    <h1>Contact <span class="ss-highlight">me</span>.</h1>

    <EmailForm />

    <div class="ss-social">
      <ul>
        <li>
          <a href="https://github.com/zeroedin">
            <font-awesome-icon :icon="['fab', 'github']" class="fa-2x" />
            <span class="sr-only">GitHub</span>
          </a>
        </li>
        <li>
          <a href="https://awscommunity.social/@stevenspriggs">
            <font-awesome-icon :icon="['fab', 'mastodon']" class="fa-2x" />
            <span class="sr-only">Mastadon</span>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/stevespriggs/">
            <font-awesome-icon :icon="['fab', 'linkedin']" class="fa-2x" />
            <span class="sr-only">LinkedIn</span>
          </a>
        </li>
        <li>
          <a href="https://instagram.com/stevenspriggs">
            <font-awesome-icon :icon="['fab', 'instagram']" class="fa-2x" />
            <span class="sr-only">Instagram</span>
          </a>
        </li>
        <li>
          <a href="https://flickr.com/stevenspriggs">
            <font-awesome-icon :icon="['fab', 'flickr']" class="fa-2x" />
            <span class="sr-only">Flickr</span>
          </a>
        </li>

        <li>
          <a href="https://youtube.com/stevenspriggs">
            <font-awesome-icon :icon="['fab', 'youtube']" class="fa-2x" />
            <span class="sr-only">Youtube</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import EmailForm from "@/components/EmailForm";

export default {
  name: "Contact",
  components: {
    EmailForm,
  },
};
</script>

<template>
  <div v-if="thanks" class="ss-thanks">
    Thanks for sending me a message. I'll get back to you as soon as possible.
  </div>

  <div v-if="error" class="ss-error">
    {{ error.error }}
  </div>

  <form class="ss-card" v-on:submit.prevent="submitForm">
    <label for="name" class="ss-label ss-item">Name</label>
    <input
      type="text"
      name="name"
      id="name"
      v-model="form.name"
      class="ss-item"
      placeholder="Enter your name"
      required="true"
    />
    <label for="from" class="ss-label ss-item">Email</label>
    <input
      type="email"
      name="from"
      id="from"
      v-model="form.from"
      class="ss-item"
      placeholder="Enter your email"
      required="true"
    />
    <label for="message" id="message" class="ss-label ss-item">Message</label>
    <textarea
      rows="10"
      cols="50"
      name="message"
      v-model="form.message"
      class="ss-item"
      required="true"
    />
    <button
      type="submit"
      class="ss-item ss-shift"
      @click="submitForm"
      :disabled="submitting"
    >
      Send it
    </button>
  </form>
</template>

<script>
import axios from "axios";
const API = process.env.VUE_APP_API_GATEWAY;

export default {
  name: "EmailForm",
  data() {
    return {
      form: {
        name: "",
        from: "",
        message: "",
      },
      submitting: false,
      thanks: false,
      error: false,
    };
  },
  methods: {
    submitForm: function (e) {
      e.preventDefault();
      this.error = false;
      this.submitting = true;
      axios
        .post(API, this.form)
        .then(() => {
          this.thanks = true;
        })
        .catch((error) => {
          this.submitting = false;
          this.error = error.response.data;
        });
    },
  },
};
</script>
